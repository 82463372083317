import { formatFullName } from '../../utils';

export const getOwnerById = ({ items }, { id }) => {
  return items[id] || {};
};

export const getOwnersListForEditor = ({ items }) => {
  return Object.values(items).map(({ id, ...owner }) => ({
    id,
    value: formatFullName(owner, true),
  }));
};

export const getOwnersList = ({ items }) => {
  return Object.values(items);
};

export const getOwnersByIDs =
  ownerIds =>
  ({ owners }) => {
    return ownerIds.map(id => owners?.items[id] || null).filter(Boolean);
  };
