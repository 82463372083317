import userService from '../../services/user.service';
import {
  APPLICATION_REFRESH_DATE,
  APP_IS_READY,
  AUTH_LOGIN,
  AUTH_UPDATE_TOKEN,
  AUTH_UPDATE_USER,
  NOTIFICATION_ADD_TAG,
  ORGANIZATION_UPDATE,
  WORKSPACES_SELECT_WORKSPACE,
  WORKSPACES_UPDATE_CONTENT,
} from '../actions';
import { fetchWorkspaces } from '../actions/workspace.actions';

export const workspaceFetchDataMiddleware =
  ({ dispatch }) =>
  next =>
  async action => {
    next(action);

    if (
      action.type === AUTH_LOGIN ||
      action.type === APP_IS_READY ||
      action.type === AUTH_UPDATE_TOKEN ||
      action.type === APPLICATION_REFRESH_DATE
    ) {
      if (!userService.isAuthenticated) return false;

      dispatch(fetchWorkspaces());
    }
  };

export const workspaceUpdateMiddleware =
  ({ dispatch, getState }) =>
  next =>
  async action => {
    next(action);
    if (
      action.type === WORKSPACES_UPDATE_CONTENT ||
      action.type === ORGANIZATION_UPDATE ||
      action.type === AUTH_UPDATE_USER
    ) {
      const state = getState();
      const { activeWorkspaces = [] } = state?.auth?.user ?? {};

      const organizationId = state?.organization.id;
      const workspaceList = state?.workspace.list;

      // get active workspace for this org
      const workspaceInThisOrg = activeWorkspaces.find(w => w?.organizationId === organizationId);

      // check if workspace still exists
      const workspaceExists = workspaceList.find(w => w?.id === workspaceInThisOrg?.workspaceId);

      let selected = workspaceInThisOrg?.workspaceId;

      if (!workspaceExists || !selected) {
        selected = workspaceList?.[0]?.id;
      }

      dispatch({
        type: WORKSPACES_SELECT_WORKSPACE,
        payload: { selected },
      });

      dispatch({
        type: NOTIFICATION_ADD_TAG,
        payload: { key: 'workspaceId', value: selected },
      });
    }
  };

export const selectWorkspaceMiddleware =
  ({ dispatch }) =>
  next =>
  async action => {
    next(action);
    if (action.type === WORKSPACES_SELECT_WORKSPACE) {
      dispatch({
        type: NOTIFICATION_ADD_TAG,
        payload: { key: 'workspaceId', value: action.payload.selected },
      });
    }
  };

export const workspaceMiddleware = [
  workspaceFetchDataMiddleware,
  workspaceUpdateMiddleware,
  selectWorkspaceMiddleware,
];
