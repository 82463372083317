import {
  activateAccount,
  attachUserDetails,
  attachUserId,
  logout,
  setRegion,
  signIn,
  updateAuthenticationContext,
} from '../store/actions/auth.actions';
import { ServiceBase } from './service.base';
import AuthenticationServiceApi from '../api/authentication.service.api';
import {
  GENERIC_SERVER_ERROR_MESSAGE,
  ORGANIZATION_UNAUTHORIZED,
} from '../constants/applicationErrorCodes';
import { navigate } from './navigation.service';
import { ROUTES, DEVICE_FLAGS, AUTHENTICATION_TYPE } from '../constants';
import { APPLICATION_STEPS } from '../constants/applicationSteps';
import { finishStep } from '../store/actions/application.actions';
import userServiceApi from '../api/user.service.api';
import API from '../api/API';
import authService from './auth.service';
import { currentEnvironment } from './Environment';
import { switchOrganization, updateOrganization } from '../store/actions/organization.actions';
import authenticationServiceApi from '../api/authentication.service.api';

class UserService extends ServiceBase {
  constructor(props) {
    super(props);
    this.reducer = 'auth';
    this.hasToCheckAuthContext = false;
  }

  /**
   * Signin user
   * @param {*} { email }
   * @returns
   * @memberof UserService
   */
  async signin({ email }) {
    try {
      const { region } = await this._selectRegion({
        email,
      });

      if (!region) {
        navigate(ROUTES.NOT_ALLOWED, { email });
        return;
      }

      this.dispatch(setRegion({ region }));
    } catch (e) {
      return { error: e.message };
    }

    const { error, data } = await AuthenticationServiceApi.loginWithEmail({
      email,
    });

    if (error) {
      if (data.code === ORGANIZATION_UNAUTHORIZED) {
        console.info(data.message);
        navigate(ROUTES.NOT_ALLOWED, { email });
        return { error: '' };
      }
      return { error: GENERIC_SERVER_ERROR_MESSAGE };
    }

    const { id, authType = AUTHENTICATION_TYPE.OTP } = data;
    // Attach user ID
    this.dispatch(attachUserDetails({ email }));
    this.dispatch(attachUserId({ id }));

    if (authType === AUTHENTICATION_TYPE.OTP) {
      // User have created an OTP
      navigate(ROUTES.OTP_VERIFICATION, { email });
    }

    return data;
  }

  /**
   * Contact us
   * @param {*} { email, fullName, phone }
   * @returns
   * @memberof UserService
   */
  async contactUs({ email, fullName, phone }) {
    let { error, data } = await AuthenticationServiceApi.contactUs({
      email,
      fullName,
      phone,
    });

    if (error) {
      return { error: data.message };
    }

    return data;
  }

  /**
   * Verify OTP code and sign if needed
   * @param {*} { code }
   * @returns
   * @memberof UserService
   */
  async verifyCode({ code, userId }) {
    let { error, data } = await AuthenticationServiceApi.verifyCode({
      code,
      userId: userId || this.state.id,
    });

    if (error) {
      console.log(`Fail to verify code [${code}] ${data.message}`);
      return { error: data.message, code: data.code };
    }

    console.log('Signin - Succeeded');
    // Data include token and user
    this.dispatch(signIn(data));

    return {};
  }

  /**
   * Signin with oauth
   * @param {*} { provider: String, codeVerifier: String, redirectUri:String, clientId: String, code: String }
   * @returns
   * @memberof UserService
   */
  async oauthLogin({ provider, codeVerifier, redirectUri, clientId, code }) {
    let { error, data } = await AuthenticationServiceApi.oauthVerification({
      provider,
      codeVerifier,
      redirectUri,
      clientId,
      code,
    });

    if (error) {
      if (data.code === ORGANIZATION_UNAUTHORIZED) {
        console.info(data.message);
        navigate(ROUTES.NOT_ALLOWED, { email: '' });
        return { error: '' };
      }
      return { error: GENERIC_SERVER_ERROR_MESSAGE };
    }

    console.log('Signin - Succeeded');
    // Data include token and user
    this.dispatch(signIn(data));

    return {};
  }

  /**
   * Activate oauth organization
   * @param {*} { provider: String, codeVerifier?: String, redirectUri:String, clientId: String, code: String }
   * @returns
   * @memberof UserService
   */

  async activateOauth({ provider, codeVerifier, redirectUri, clientId, code }) {
    let { error, data } = await AuthenticationServiceApi.activateOAuthAccount({
      provider,
      codeVerifier,
      redirectUri,
      clientId,
      code,
    });

    if (error) {
      if (data.code === ORGANIZATION_UNAUTHORIZED) {
        console.info(data.message);
        navigate(ROUTES.NOT_ALLOWED, { email: '' });
        return { error: '' };
      }
      return { error: GENERIC_SERVER_ERROR_MESSAGE };
    }

    this.dispatch(switchOrganization({ id: data.organizationId }));
    this.dispatch(activateAccount());

    return data;
  }

  /**
   *
   *
   * @returns
   * @memberof UserService
   */
  async init() {
    if (this.hasToCheckAuthContext) {
      // perform api request to get user context
      const authContext = await authenticationServiceApi.authContext();

      if (authContext.success) {
        this.dispatch(updateAuthenticationContext(authContext.data));
      }
    }

    // Check preconditions for init
    const isOnboardingFinished = this.storeState.deviceFlags[DEVICE_FLAGS.ONBOARDING_FINISHED];

    // Check if user is not authenticated
    // If not, finish init and start application
    if (!this.isAuthenticated) {
      console.log('User service - user has no valid token');

      // Check if user has refresh token - if has, force local logout
      if (this.refreshToken) {
        this._forceLogout({ skipApiCall: true });
      }

      if (!isOnboardingFinished) {
        navigate(ROUTES.ONBOARDING);
      }

      this._finishAuthStep();
      return;
    }

    // User has valid authentication credentials
    // Perform refresh token if needed
    const isSuccessful = await this._refreshAccessTokenThatIsExpiringSoon();

    // Handle error while trying to refresh token
    if (!isSuccessful) {
      this.dispatch(logout({ skipApiCall: true }));
      this._finishAuthStep();
      return;
    }

    const { error, data } = await userServiceApi.me();

    // Handle error while trying to fetch user details
    if (error?.response?.status === 401) {
      console.log('User service - user is not authenticated');
      this._forceLogout({ skipApiCall: true });
      this._finishAuthStep();
      return;
    }

    console.log('User service - user is authenticated');

    // Attach user details
    this.dispatch(attachUserDetails(data.user));

    // Fetch user's organization
    await this.dispatch(updateOrganization());

    // Finish application step
    this._finishAuthStep();
  }

  /**
   * Select allowed region for authentication and further requests
   * @param {*} { email }
   * @private
   * @returns { region }
   * @memberof UserService
   */
  async _selectRegion({ email }) {
    const regionsAvailableResponse = await AuthenticationServiceApi.checkAvailabilityByRegion({
      email,
    });

    const index = regionsAvailableResponse.findIndex(({ data }) => data?.isAvailable);
    const error = regionsAvailableResponse.find(({ error }) => error)?.error;

    // handle error while trying checking region availability e.g. 429 - too many requests
    // doesn't throw an error in development mode to allow running only one region's backend service
    if (error && currentEnvironment() !== 'development') {
      throw new Error(GENERIC_SERVER_ERROR_MESSAGE);
    }

    const allowedRegion = API.getAPIRegions()[index];

    API.setClientByRegion(allowedRegion);

    return { region: allowedRegion };
  }

  /**
   * Handle access token refresh if token is expiring soon
   * @private
   * @memberof UserService
   */
  async _refreshAccessTokenThatIsExpiringSoon() {
    const hoursBeforeExpiration =
      this.storeState.generalConfig.HOURS_BEFORE_TOKEN_EXPIRATION_TO_REFRESH;

    // Check if token is expiring soon
    const tokenWillExpireSoon = this.tokenExpiration < hoursBeforeExpiration * 60 * 60 * 1000;

    console.log(
      `User service - token expiration check. tokenWillExpireSoon: ${tokenWillExpireSoon}, tokenExpiration: ${this.tokenExpiration}`,
    );

    // If token is not expiring soon, do nothing
    // Return true to indicate that token is not expiring soon
    if (!tokenWillExpireSoon) {
      return true;
    }

    console.log(`User service - token will expire in less than ${hoursBeforeExpiration} hours `);

    const refreshSuccessful = await authService.refreshToken();

    if (refreshSuccessful) {
      console.log('User service - token refreshed successfully');
    }

    return refreshSuccessful;
  }

  _finishAuthStep() {
    this.dispatch(finishStep({ step: APPLICATION_STEPS.AUTHENTICATION_PROCESS }));
  }

  _forceLogout({ skipApiCall = false } = {}) {
    this.dispatch(logout({ skipApiCall }));
  }

  get userId() {
    return this.state.id || null;
  }

  get organizationId() {
    return this.state.user?.organizationId || null;
  }

  get region() {
    return this.state?.region || null;
  }

  // ========== Auth methods =============
  get accessToken() {
    let { accessToken } = this.state;

    return accessToken;
  }

  get tokenExpiration() {
    let { tokenDetails } = this.state;
    return 1000 * tokenDetails?.exp - Date.now();
  }

  get isTokenExpired() {
    let { tokenDetails } = this.state;
    if (tokenDetails && tokenDetails.exp) {
      return 1000 * tokenDetails.exp - Date.now() < 5000;
    }
    return true;
  }

  // Refresh token actions
  get refreshToken() {
    let { refreshToken } = this.state;

    return refreshToken;
  }

  get refreshTokenExpiration() {
    let { refreshTokenDetails } = this.state;
    return 1000 * refreshTokenDetails?.exp - Date.now();
  }

  get isRefreshTokenExpired() {
    let { refreshTokenDetails } = this.state;
    if (refreshTokenDetails && refreshTokenDetails.exp) {
      return 1000 * refreshTokenDetails.exp - Date.now() < 5000;
    }
    return this.isTokenExpired;
  }

  get isAuthenticated() {
    return !this.isRefreshTokenExpired;
  }
}

export default new UserService();
