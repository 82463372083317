export const findDefaultScheduleUrl = (schedules = []) =>
  schedules.find(schedule => !schedule.roles?.length && !schedule.professions?.length)?.url;

export const findScheduleByProfessionAndRoles = (schedules = [], user = {}) => {
  const { profession, professional = {} } = user;
  const { role } = professional || {};
  return schedules.find(
    schedule =>
      schedule.roles?.find(r => r === role) || schedule.professions?.find(p => p === profession),
  )?.url;
};

export const getMobileWidgets = ({ organization }) =>
  organization?.configuration?.dashboardWidgets?.mobile;

export const getQuickLinks = ({ organization }) => organization?.configuration?.quickLinks;

export const getOrganizationLocale = ({ organization }) => organization.locale;
