import { KNOWLEDGE_HIDE_EXPIRED_CONTENT_AFTER_IN_DAYS, REVISION_LOG_ADVISORS } from '../constants';
import { DateTime } from 'luxon';
import _ from 'lodash';

const expirationDate = DateTime.now()
  .plus({
    days: -KNOWLEDGE_HIDE_EXPIRED_CONTENT_AFTER_IN_DAYS,
  })
  .endOf('day');

export const filterByExpiryDate = ({ expiresAt }) => {
  if (_.isNull(expiresAt) || _.isUndefined(expiresAt)) {
    return true;
  }

  return expiresAt && expirationDate.diff(DateTime.fromISO(expiresAt).endOf('day')) <= 0;
};

/**
 * Check if expiry date is arrived
 * True - show files
 * False - hide files
 *
 * @param {Boolean} { adminMode }
 */
export const useExpiryFilterWithAdmin = ({ adminMode }) => {
  if (adminMode) {
    return () => true;
  }
  return filterByExpiryDate;
};

/**
 * Get knowledge item updated by info
 * @param {Object} { updatedBy, updatedAt } - updatedBy, updatedAt from knowledge
 * @returns {Object} { updatedByName, updatedByEmail, updatedAtDate }
 */
export const useUpdatedByInfo = ({ updatedBy, updatedAt }) => {
  const isC8User =
    REVISION_LOG_ADVISORS.emailDomain.test(updatedBy?.email) ||
    updatedBy?.email === REVISION_LOG_ADVISORS.name;
  const updatedByName = isC8User
    ? REVISION_LOG_ADVISORS.name
    : updatedBy?.lastName
    ? `${updatedBy?.firstName} ${updatedBy?.lastName}`
    : updatedBy?.firstName;
  const updatedByEmail = isC8User ? REVISION_LOG_ADVISORS.supportEmail : updatedBy?.email;

  return { updatedByName, updatedByEmail, updatedAtDate: updatedAt };
};
