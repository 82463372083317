import sanitizeHtml from 'sanitize-html';
import {
  ALLOW_EMBEDDED_URLS,
  // UP_TO_DATE_SYSID,
  UP_TO_DATE_URL,
} from 'shared/constants';
import { ANALYTICS_CATEGORIES_TYPES, CATEGORY_TYPES } from '../constants';
import _ from 'lodash';

async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    // eslint-disable-next-line no-await-in-loop
    await callback(array[index], index, array);
  }
}

const timeout = ms => new Promise(resolve => setTimeout(resolve, ms));

const toBase64 = text => Buffer.from(text).toString('base64');

const queryString = params =>
  Object.keys(params)
    .map(key => key + '=' + encodeURIComponent(params[key]))
    .join('&');

const convertToSec = (time = Date.now()) => (time / 1000) | 0;

const nowSEC = () => (Date.now() / 1000) | 0;

const getParentIdByPath = (path = '') => {
  if (!path) return null;

  return path.split(',').pop();
};

const camelCaseToWords = function (str) {
  str = str.replace(/_/, ' ').toLowerCase();
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const removeDiacritics = str => {
  return str?.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};

// Function to escape special characters in the string to make it safe for RegExp
const escapeRegExp = str => {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // Escapes special characters
};

const normalizedContains = (haystack, needle) => {
  const safeNeedle = escapeRegExp(removeDiacritics(needle)); // Escape special characters in needle
  const regExp = new RegExp(safeNeedle, 'gi');
  return regExp.test(removeDiacritics(haystack));
};

const isUndefinedOrNull = value => value === undefined || !value;

const sortByAlphabet = (a, b) => {
  if (isUndefinedOrNull(a) && isUndefinedOrNull(b)) return 0;
  if (isUndefinedOrNull(a)) return 1;
  if (isUndefinedOrNull(b)) return -1;
  return a.localeCompare(b);
};

const parseYoutubeUrl = link => {
  const standardPattern =
    /^https?:\/\/(?:www\.)?youtube\.com\/watch\?(?:.*&)?v=(?<videoId>[^\s&]+)/;
  const shortenedPattern = /^https?:\/\/youtu.be\/(?<videoId>[^\s&]+)/;

  const standardMatch = link.match(standardPattern);
  const shortenedMatch = link.match(shortenedPattern);

  const EMBED_LINK = 'https://www.youtube.com/embed/';

  if (standardMatch && standardMatch.groups.videoId) {
    const { videoId } = standardMatch.groups;
    return `${EMBED_LINK}${videoId}`;
  } else if (shortenedMatch && shortenedMatch.groups.videoId) {
    const { videoId } = shortenedMatch.groups;
    return `${EMBED_LINK}${videoId}`;
  }

  // Invalid YouTube link
  return '';
};

const isVideoUrl = url => {
  // we can later add more video providers like vimeo
  return /youtube.com\/watch|youtu.be/.test(url);
};

const isGoogleUrl = url => {
  return /docs.google.com\/(spreadsheets|presentation|document)/.test(url);
};

const isVideoByFormat = link => {
  const videoFormats = ['mp4', 'mov', 'avi', 'wmv', 'flv', '3gp', 'webm', 'mkv'];
  const format = link?.split('.')[link?.split('.').length - 1];
  return videoFormats.includes(format);
};

// same sanitization rules as in backend
const sanitizeHtmlContent = (html, config) => {
  const localConfig = {
    allowedURLs: ALLOW_EMBEDDED_URLS,
    ...config,
  };

  function isURLAllowed(url) {
    // Check if any allowedURL is the beginning of the URL
    return localConfig.allowedURLs.some(allowedURL => url.startsWith(allowedURL));
  }

  return sanitizeHtml(html, {
    allowedTags: sanitizeHtml.defaults.allowedTags.concat(['img', 'video', 'source', 'iframe']),
    allowedAttributes: {
      p: ['style'],
      h1: ['style'],
      h2: ['style'],
      h3: ['style'],
      span: ['style'],
      div: ['style'],
      a: ['href', 'name', 'target'],
      img: ['style', 'src', 'alt', 'title', 'width', 'height'],
      td: ['colspan', 'rowspan'],
      th: ['colspan', 'rowspan'],
      tr: ['colspan', 'rowspan'],
      table: ['cellspacing', 'cellpadding'],
      video: ['style', 'controls', 'src'],
      source: ['src', 'type'],
      iframe: ['src', 'allowfullscreen'],
    },
    allowedSchemesAppliedToAttributes: ['href', 'src'],
    transformTags: {
      a: sanitizeHtml.simpleTransform('a', { target: '_blank', rel: 'noopener noreferrer' }),
      iframe: function (tagName, attribs) {
        // Only allow certain trusted domains for iframe src
        if (attribs.src && isURLAllowed(attribs.src)) {
          return {
            tagName: tagName,
            attribs: {
              ...attribs,
              src: attribs.src,
            },
          };
        }
        // remove iframe if it doesn't meet the criteria
        return false;
      },
    },
    parseStyleAttributes: false,
  });
};

const getCookie = ({ name }) =>
  document.cookie
    .split('; ')
    .find(cookie => cookie.includes(name))
    ?.split('=')[1];

const getIdfromUrl = ({ url }) => url.split('/').pop();
const getIdfromQueryParam = ({ url }) => url.split('=').pop();
// we need this because some urls have multiple query params
// we should consider using URLSearchParams instead of this
// will need a new native deploy, because we need the 'react-native-url-polyfill' as a dev dependency
const newGetIdfromQuery = ({ url }) => url.split('id=').pop();
const generateUpToDateLink = (searchTerm, unId) => {
  const params = new URLSearchParams(
    _.omitBy(
      {
        search: searchTerm,
        // unid: unId,
        // srcsys: UP_TO_DATE_SYSID,
      },
      _.isEmpty,
    ),
  );

  const link = `${UP_TO_DATE_URL}?${params.toString()}`;

  return link;
};

const isAppUrl = url => {
  const appUrlRegex = /^https:\/\/(dev\.|staging\.)?app\.(headtotoe\.io|c8\.health|c8health\.com)/;
  return url.match(appUrlRegex)?.length;
};

const formatFullName = (user = {}, withEmail = false) => {
  const { firstName, lastName, email } = user || {};

  if (!firstName && !lastName) {
    return email || '';
  }

  const emailSufix = withEmail && email ? ` (${email})` : '';
  return `${firstName ?? ''} ${lastName ?? ''}`.trim().concat(emailSufix);
};

const getCategoryType = ({ path, type, isNew, isSectionCreation }) => {
  if (isNew) {
    if (isSectionCreation) {
      return ANALYTICS_CATEGORIES_TYPES.SECTION;
    } else if (!isSectionCreation && type === CATEGORY_TYPES.CATEGORY) {
      return ANALYTICS_CATEGORIES_TYPES.DIRECTORY;
    } else {
      return ANALYTICS_CATEGORIES_TYPES.BINDER;
    }
  } else {
    const hasRoot = new RegExp(/ROOT/gi).test(path);
    let pathPartsLen = path.split(',').length;
    pathPartsLen = hasRoot ? pathPartsLen : pathPartsLen + 1;
    if (type === CATEGORY_TYPES.CATEGORY && pathPartsLen % 2 === 0) {
      return ANALYTICS_CATEGORIES_TYPES.SECTION;
    } else if (type === CATEGORY_TYPES.CATEGORY && pathPartsLen % 2 !== 0) {
      return ANALYTICS_CATEGORIES_TYPES.DIRECTORY;
    } else {
      return ANALYTICS_CATEGORIES_TYPES.BINDER;
    }
  }
};

export {
  asyncForEach,
  timeout,
  toBase64,
  queryString,
  convertToSec,
  nowSEC,
  getParentIdByPath,
  camelCaseToWords,
  removeDiacritics,
  normalizedContains,
  sortByAlphabet,
  parseYoutubeUrl,
  isVideoUrl,
  isGoogleUrl,
  isVideoByFormat,
  sanitizeHtmlContent,
  getCookie,
  getIdfromUrl,
  getIdfromQueryParam,
  newGetIdfromQuery,
  generateUpToDateLink,
  isAppUrl,
  formatFullName,
  getCategoryType,
};
